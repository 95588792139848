import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    VerticalLayout1Module,
    TranslateModule,
    FlexModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [VerticalLayout1Module, AuthLayoutComponent],
  declarations: [AuthLayoutComponent],
})
export class LayoutModule {}
