import { NgModule } from '@angular/core';

import { FilePreviewDialogComponent } from './file-preview-dialog/file-preview-dialog.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { PruningTranslationLoader } from '@app/shared/helpers/pruning-translation-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { QuotationService } from '@app/main/quotation/quotation.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new PruningTranslationLoader(http);
}

@NgModule({
  declarations: [FilePreviewDialogComponent, FileViewerComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      isolate: false,
    }),
    MatProgressSpinnerModule,
    MatDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [FilePreviewDialogComponent, FileViewerComponent],
  providers: [QuotationService],
})
export class FilePreviewModule {}
